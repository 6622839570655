.featureHeading {
  text-align: center;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 4px;
}
.productTile {
}
.cartImage {
  border-radius: 500px;
}
/* .productTile:hover {
  box-shadow: 12.5px 8.1px 6.1px -42px rgba(0, 0, 0, 0.03),
    100px 65px 49px -42px rgba(0, 0, 0, 0.06);
} */

.cardTitle {
  font-family: "Roboto", sans-serif;
  font-weight: 200;
  font-size: 20px;
  text-align: center;
}
.cardDesc {
  font-family: "Roboto", sans-serif;
  font-weight: 150;
  font-size: 12px;
  text-align: center;
}

/* .btnContainer {
          border-radius: 10px;
          background: red;
          width: 50%;
          height: 40px;
          justify-content: center;
          align-items: center;
          display: flex;
        } */
.btnTile {
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  margin-top: 20px;
  color: #855032;
}
