.footerContainer {
  height: 250px;
  width: 100%;
}
.socialIcon:hover {
  color: #855032;
  border-radius: 50;
}
.footerHeading {
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
  font-weight: 700;
  text-align: left;
}

.footerPara {
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
  font-weight: 200;
  margin-bottom: 2px;
  text-align: left;
  color: white;
}
