.mainContainer {
  width: 100%;
  /* background: red; */
}

.videoBgDiv {
  z-index: 9;
  margin: 30px;
  /* background: red; */
}


