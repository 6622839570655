.aboutCol {
  height: 500px;
}
.colHeading {
  font-size: 20px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.colAboutUs {
  justify-content: center;

  display: flex;
  flex-direction: column;
}

.colPara {
  font-size: 15px;
  font-weight: 100px;
  font-family: "Roboto", sans-serif;
}
.contactContainer {
  justify-content: center;
  display: flex;
  flex-direction: column;
}
