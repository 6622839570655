.mainConteiner {
  height: 50vh;
  width: 100%;
}
.catHeading {
  text-align: center;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 4px;
}
.categoryTile {
  height: 500px;
  overflow: hidden;
  margin-top: 10px;
}

.catImage {
  object-fit: cover;
}
.catImage {
  border-radius: 20px;
}

.catImage:hover {
  border-radius: 20px;
}

.canName {
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
  font-size: 15px;
}

.catContainer {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
}

.catImage:hover {
  box-shadow: 5px 5px 10px #656565, -5px -5px 10px #ffffff;
  object-fit: cover;
}
