.imgConntainer {
  height: 50%;
}
.titleHeadig {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
}
.paragraph {
  font-size: 15px;
  font-family: "Roboto", sans-serif;
}
