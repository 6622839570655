.navContainer {
  /* position: sticky; */

  background-color: #00000099;
  /* top: 0px; */
}
.navTitle {
  font-family: "Roboto", sans-serif;
  color: white;
}
.brandName {
  color: white;
  font-family: "Roboto", sans-serif;
  margin-top: 12px;
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: 50px;
}
