.mainContainer {
  margin-top: 20px;
}

.vImage {
  width: 100%;
}

.cImage1 {
  height: 350px;
  background-color: green;
}

.cImage2 {
  height: 350px;
  background-color: blue;
}

.cImage3 {
  height: 350px;
  background-color: pink;
}
.cImage4 {
  height: 350px;
  background-color: black;
}
